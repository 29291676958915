/******* HEADER *******/
.commentsDrawer > div:last-child > div > div > div:first-child {
  background: #3f505a;
  color: #ffffff;
}
.commentsDrawer > div:last-child > div > div > div:first-child > div {
  flex-direction: row-reverse;
}
.commentsDrawer
  > div:last-child
  > div
  > div
  > div:first-child
  > div
  > div:last-child
  > div {
  display: flex;
  align-items: center;
}
.commentsDrawer > div:last-child > div > div > div:first-child span,
.commentsDrawer > div:last-child > div > div > div:first-child button,
.commentsDrawer > div:last-child > div > div > div:first-child svg {
  color: white;
}
.commentsDrawer > div:last-child > div > div > div:first-child button {
  margin: 0;
  padding: 0;
}
/******* BODY *******/
ul {
  padding: 0;
}
ul li {
  list-style-type: none;
}
.commentsDrawer > div:nth-child(2) {
  width: 100% !important;
  @media (min-width: 768px) {
    width: 378px !important;
  }
}
.commentsDrawer textarea {
  width: 100%;
  min-height: 48px;
  margin-right: 10px;
  border: none;
  resize: none;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
textarea:focus {
  outline: 1px solid #b3bfc6;
}
.commentsDrawer textarea::placeholder,
.commentsDrawer textarea {
  font-size: 16px;
}
.commentsDrawer > div:last-child > div > div > div:nth-child(2) {
  padding: 16px;
  background: #fafcfd;
  @media (min-width: 768px) {
    padding: 24px;
  }
}
.comments {
  color: #3f505a;
  margin-bottom: 16px;
}
/******* FOOTER *******/
.commentsFooter {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  width: 330px;
  padding: 8px 0;
  border-top: 1px solid #d5dbdf;
}
.commentsDrawer .sendBtn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.commentsDrawer .isDisabled,
.commentsDrawer .isDisabled:hover,
.commentsDrawer .isDisabled:active,
.commentsDrawer .isDisabled:focus {
  background: #d5dbdf;
}
.sendBtn > span,
.sendBtn svg {
  position: relative;
  left: 1px;
  width: 24px;
  height: 24px;
}
.commentsDrawer .isActive,
.commentsDrawer .isActive:hover,
.commentsDrawer .isActive:active,
.commentsDrawer .isActive:focus {
  background: #0091b5;
}
/******* SCROLL *******/
.commentsDrawer
  > div:last-child
  > div
  > div
  > div:nth-child(2)::-webkit-scrollbar {
  width: 14px;
  background: #ebf0f3;
}
.commentsDrawer
  > div:last-child
  > div
  > div
  > div:nth-child(2)::-webkit-scrollbar-thumb {
  width: 8px;
  height: 70px;
  background: #b3bfc6;
  border-radius: 30px;
}
