/********************* FOR ORDERS *************************/
$ff-color-order-Draft: #3f505a;
$ff-bg-order-Draft: #f3efeb;

$ff-color-order-Template: #3f505a;
$ff-bg-order-Template: #d5dbdf;

$ff-color-order-Submitted: #c16a00;
$ff-bg-order-Submitted: #fdead2;

$ff-color-order-Pending: #007d9c;
$ff-bg-order-Pending: #dcf2f5;

$ff-color-order-Rejected: #cb1b16;
$ff-bg-order-Rejected: #fae8e8;

$ff-color-order-Approved: #2e8206;
$ff-bg-order-Approved: #e2f5da;

$ff-color-order-Published: #2e8206;
$ff-bg-order-Published: #e2f5da;

$ff-color-order-Completed: #ffffff;
$ff-bg-order-Completed: #4ebccf;

$ff-color-order-Expired: #bd223d;
$ff-bg-order-Expired: #f7cdd4;

$ff-color-order-Cancelled: #ffffff;
$ff-bg-order-Cancelled: #ff9c94;

/********************* FOR FULFILMENTS *************************/

$ff-color-fulfilment-Accepted: #c16a00;
$ff-bg-fulfilment-Accepted: #fdead2;

$ff-color-fulfilment-Available: #2e8206;
$ff-bg-fulfilment-Available: #e2f5da;

$ff-color-fulfilment-Expired: #bd223d;
$ff-bg-fulfilment-Expired: #f7cdd4;

$ff-color-fulfilment-Submitted: #ffffff;
$ff-bg-fulfilment-Submitted: #d67500;

$ff-color-fulfilment-Pending: #007d9c;
$ff-bg-fulfilment-Pending: #dcf2f5;

$ff-color-fulfilment-Completed: #ffffff;
$ff-bg-fulfilment-Completed: #52a436;

$ff-color-fulfilment-Rejected: #cb1b16;
$ff-bg-fulfilment-Rejected: #fae8e8;

$ff-color-fulfilment-Declined: #ffffff;
$ff-bg-fulfilment-Declined: #ff9c94;

$ff-color-fulfilment-Returned: #3f505a;
$ff-bg-fulfilment-Returned: #d5dbdf;

/********************* FOR CONTENTS *************************/

$ff-color-content-Processing: #007d9c;
$ff-bg-content-Processing: #dcf2f5;

$ff-color-content-Processed: #ffffff;
$ff-bg-content-Processed: #4ebccf;

$ff-color-content-Submitted: #ffffff;
$ff-bg-content-Submitted: #d67500;

$ff-color-content-Failed: #ffffff;
$ff-bg-content-Failed: #ff9c94;

$ff-color-content-Approved: #2e8206;
$ff-bg-content-Approved: #e2f5da;

$ff-color-content-Rejected: #cb1b16;
$ff-bg-content-Rejected: #fae8e8;

$ff-color-content-Delivered: #ffffff;
$ff-bg-content-Delivered: #52a436;
