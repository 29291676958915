.carousel {
  @media (min-width: 1370px) {
    height: 660px;
  }
}
.carousel img {
  @media (min-width: 1370px) {
    object-fit: cover;
    height: 660px;
  }
}
.carousel > ul.dotsStyle {
  position: static;
  height: 48px;
  align-items: center;
  margin: 0;
  background: #fff;
  @media (min-width: 1370px) {
    height: 72px;
  }
}
.carousel > ul.dotsStyle li {
  width: 24px !important;
  height: 4px;
  margin: 0 10px 0 0;
  @media (min-width: 1370px) {
    width: 40px !important;
    margin: 0 16px 0 0;
  }
}
.carousel > ul.dotsStyle li > button {
  height: 4px;
  border: 1px solid #d5dbdf;
  border-radius: 30px;
  background: #d5dbdf;
  opacity: 1;
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
}
.modalFooter button {
  width: 156px;
  height: 48px;
  @media (min-width: 1370px) {
    width: 84px;
    height: 40px;
  }
}
.modalFooter button:first-child {
  margin-right: 16px;
}
.modalFooter button span {
  font-size: 16px;
  font-weight: 600;
}
