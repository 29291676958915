.title {
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
  }
}
.openBtn {
  margin-right: 8px;
  span {
    line-height: 1.2;
  }
}
