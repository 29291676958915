.header {
  position: sticky;
  height: 64px;
  top: 0;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(19, 43, 58, 0.06),
    0px 3px 10px rgba(19, 43, 58, 0.09);
  z-index: 200;
  font-size: 16px;
  color: #3f505a;
}
.headerWrapper {
  height: 64px;
  background: white;
}
.headerWrapper > div {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  @media (min-width: 768px) {
    padding: 0;
  }
}
.block {
  display: flex;
  align-items: center;
}
.notification {
  margin: 0 20px 0;
  @media (min-width: 1370px) {
    display: block;
  }
}
.notification {
  border: 0;
  padding: 0;
}
.notificationBtn {
  @media (min-width: 1370px) {
    margin-right: 25px;
  }
}
.hideMenu {
  display: none;
}
.notificationBtn:hover {
  cursor: pointer;
}
.menu {
  margin-top: 1px;
  z-index: 5;
  @media (min-width: 768px) and (max-width: 1369px) {
    z-index: 9;
  }
  @media (min-width: 1370px) {
    display: none;
  }
}
.menu:hover {
  cursor: pointer;
}
.hideMenu {
  @media (min-width: 768px) and (max-width: 1369px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.5;
    background-color: #132b3a;
    z-index: 2;
  }
}
.icon {
  @media (min-width: 1370px) {
    position: relative;
  }
}
.profileBtn {
  display: none;
  @media (min-width: 1370px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e6f1f3;
  }
}
.profileBtn img {
  position: absolute;
  bottom: -3px;
  right: -10px;
  width: 23px;
  height: 23px;
  background: white;
  border-radius: 50%;
  border: 2px solid #d5dbdf;
  box-shadow: 0px 1px 2px rgb(19 43 58 / 6%), 0px 3px 10px rgb(19 43 58 / 9%);
  z-index: 1;
}
.profileBtn h4 {
  font-size: 14px;
  color: #132b3a;
  font-weight: bold;
  margin: 0;
}
.profileBtn:hover h4,
.profileBtn:focus h4,
.profileBtn:active h4 {
  color: #0091b5;
}

.profileBtn:hover,
.profileBtn:focus,
.profileBtn:active {
  color: #0091b5;
  border-color: #0091b5;
  box-shadow: 0 0 0 3px#c3e0e5;
  background-color: #d5dbdf;
}
.btnSwitch {
  font-size: 16px;
  font-weight: bold;
  border: none;
  color: #03a3bf;
  margin: 16px 0;
}
.commentBtn {
  padding: 0;
  color: #132b3a;
  margin-right: 25px;
}
.badgeIcon span {
  font-size: 12px;
}
.onboardingBtn {
  display: none;
  @media (min-width: 1370px) {
    display: flex;
    margin-right: 30px;
    height: 28px;
    font-weight: 700;
  }
}

.checkInButtonContainer {
  margin-right: 20px;

  @media (min-width: 1370px) {
    margin-right: 24px;
  }
}

.checkInButtonSkeleton {
  display: flex;
  &:global(.ant-skeleton.ant-skeleton-block .ant-skeleton-button) {
    width: 100px;
    height: 36px;
  }
}

.checkInButton:global(.ant-btn-background-ghost.ant-btn-primary) {
  height: 36px;

  color: #03a3bf;
  border-color: #03a3bf;
  border-radius: 39px;
  font-weight: 700;

  &:hover {
    background-color: #03a3bf;
    color: #fff;
  }

  &:active {
    background-color: transparent;
  }

  &:focus {
    color: #03a3bf;
    background-color: #fff;
  }
}
