@import 'colors';
@import 'statuses';

.status {
  z-index: 1;
  font-weight: bold;
  color: white;
  font-size: 12px;
  letter-spacing: 0.03em;
  border-radius: 2px;
  height: 26px;
  line-height: 26px;
  display: inline-block;
  padding: 0 10px;

  /**************** FOR ORDER ***************/
  &[data-order-status='#{$DRAFT_ORDER}'] {
    color: $ff-color-order-Draft;
    background-color: $ff-bg-order-Draft;
  }
  &[data-order-status='#{$TEMPLATE_ORDER}'] {
    color: $ff-color-order-Template;
    background-color: $ff-bg-order-Template;
  }
  &[data-order-status='#{$SUBMITTED_ORDER}'] {
    color: $ff-color-order-Submitted;
    background-color: $ff-bg-order-Submitted;
  }
  &[data-order-status='#{$ACCEPTED_ORDER}'] {
    color: $ff-color-order-Submitted;
    background-color: $ff-bg-order-Submitted;
  }
  &[data-order-status='#{$PENDING_ORDER}'] {
    color: $ff-color-order-Pending;
    background-color: $ff-bg-order-Pending;
  }
  &[data-order-status='#{$REJECTED_ORDER}'] {
    color: $ff-color-order-Rejected;
    background-color: $ff-bg-order-Rejected;
  }
  &[data-order-status='#{$APPROVED_ORDER}'] {
    color: $ff-color-order-Approved;
    background-color: $ff-bg-order-Approved;
  }
  &[data-order-status='#{$PUBLISHED_ORDER}'] {
    color: $ff-color-order-Published;
    background-color: $ff-bg-order-Published;
  }
  &[data-order-status='#{$COMPLETED_ORDER}'] {
    color: $ff-color-order-Completed;
    background-color: $ff-bg-order-Completed;
  }
  &[data-order-status='#{$CANCELLED_ORDER}'] {
    color: $ff-color-order-Cancelled;
    background-color: $ff-bg-order-Cancelled;
  }

  &[data-order-status='#{$EXPIRED_ORDER}'] {
    color: $ff-color-order-Expired;
    background-color: $ff-bg-order-Expired;
  }

  /***************  FOR FULFILMETS  **************/
  &[data-fulfilment-status='#{$ACCEPTED_JOB}'] {
    color: $ff-color-fulfilment-Accepted;
    background-color: $ff-bg-fulfilment-Accepted;
  }
  &[data-fulfilment-status='#{$EXPIRED_JOB}'] {
    color: $ff-color-fulfilment-Expired;
    background-color: $ff-bg-fulfilment-Expired;
  }
  &[data-fulfilment-status='#{$SUBMITTED_JOB}'] {
    color: $ff-color-fulfilment-Submitted;
    background-color: $ff-bg-fulfilment-Submitted;
  }
  &[data-fulfilment-status='#{$PENDING_JOB}'] {
    color: $ff-color-fulfilment-Pending;
    background-color: $ff-bg-fulfilment-Pending;
  }
  &[data-fulfilment-status='#{$COMPLETED_JOB}'] {
    color: $ff-color-fulfilment-Completed;
    background-color: $ff-bg-fulfilment-Completed;
  }
  &[data-fulfilment-status='#{$REJECTED_JOB}'] {
    color: $ff-color-fulfilment-Rejected;
    background-color: $ff-bg-fulfilment-Rejected;
  }
  &[data-fulfilment-status='#{$DECLINED_JOB}'] {
    color: $ff-color-fulfilment-Declined;
    background-color: $ff-bg-fulfilment-Declined;
  }
  &[data-fulfilment-status='#{$RETURNED_JOB}'] {
    color: $ff-color-fulfilment-Returned;
    background-color: $ff-bg-fulfilment-Returned;
  }
  &[data-fulfilment-status='#{$CANCELLED_JOB}'] {
    color: $ff-color-order-Cancelled;
    background-color: $ff-bg-order-Cancelled;
  }

  /***************  FOR STATUSES  **************/
  &[data-content-status='#{$PROCESSING_CONTENT}'] {
    color: $ff-color-content-Processing;
    background-color: $ff-bg-content-Processing;
  }
  &[data-content-status='#{$PROCESSED_CONTENT}'] {
    color: $ff-color-content-Processed;
    background-color: $ff-bg-content-Processed;
  }
  &[data-content-status='#{$SUBMITTED_CONTENT}'] {
    color: $ff-color-content-Submitted;
    background-color: $ff-bg-content-Submitted;
  }
  &[data-content-status='#{$FAILED_CONTENT}'] {
    color: $ff-color-content-Failed;
    background-color: $ff-bg-content-Failed;
  }
  &[data-content-status='#{$APPROVED_CONTENT}'] {
    color: $ff-color-content-Approved;
    background-color: $ff-bg-content-Approved;
  }
  &[data-content-status='#{$REJECTED_CONTENT}'],
  &[data-content-status='#{$DUPLICATED_CONTENT}'],
  &[data-content-status='#{$OUT_OF_FOCUSED_CONTENT}'] {
    color: $ff-color-content-Rejected;
    background-color: $ff-bg-content-Rejected;
  }
  &[data-content-status='#{$DELIVERED_CONTENT}'] {
    color: $ff-color-content-Delivered;
    background-color: $ff-bg-content-Delivered;
  }
}
.antBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.antBtnPrimary {
  color: #fff;
  background: #03a3bf;
  border-color: #03a3bf;
}
.linkBtn {
  color: #0091b5;
  padding: 0;
}
.linkBtn:hover,
.linkBtn:active,
.linkBtn:focus {
  color: #007d9c;
}
.dangerBtn {
  color: #fc594c;
  border-color: #fc594c;
}
.divider {
  margin: 28px 0;
  color: #d5dbdf;
}
.homePage_filterButtons::-webkit-scrollbar {
  height: 3px;
  @media (min-width: 1370px) {
    height: 5px;
  }
}
.homePage_filterButtons::-webkit-scrollbar-thumb {
  height: 3px;
  background-color: #0091b5;
  border-radius: 9px;
  @media (min-width: 1370px) {
    border-radius: 3px;
  }
}
.homePage_filterButtons::-webkit-scrollbar-track {
  border: 2px solid #d5dbdf;
  @media (min-width: 1370px) {
    border: 5px solid #d5dbdf;
    border-radius: 9px;
  }
}
.buttonDanger {
  color: white;
  background: #fc594c;
  border-color: #fc594c;
  transition: all 0.3s ease-out;
}
.buttonDanger:hover,
.buttonDanger:active,
.buttonDanger:focus {
  color: white;
  background: #ff9c94;
  border-color: #ff9c94;
}
.buttonCancel {
  color: #3f505a;
  transition: all 0.3s ease-out;
}
.buttonCancel:hover,
.buttonCancel:active,
.buttonCancel:focus {
  color: #03a3bf;
  background: #f3f7f9;
  border-color: #03a3bf;
  box-shadow: 0px 0px 4px #03a3bf;
}

.userLevelAmateur {
  color: #bd223d;
  font-weight: 600;
}
.userLevelProfessional,
.userLevelEnthusiast {
  color: #d67500;
  font-weight: 600;
}
