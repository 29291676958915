.nav {
  position: relative;
  height: calc(100vh - 65px);
  background-color: #fff;
  z-index: 5;
  padding: 24px 16px;
  overflow-y: auto;
  line-height: 28px;
  @media (min-width: 768px) and (max-width: 1369px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 100vh;
    background-color: #fff;
    z-index: 5;
    padding: 60px 16px 16px;
    box-shadow: 0px 0px 10px rgba(19, 43, 58, 0.05),
      0px 4px 20px rgba(19, 43, 58, 0.1);
  }
  @media (min-width: 1370px) {
    position: relative;
    top: -10px;
    min-width: 302px;
    height: auto;
    float: right;
    box-shadow: 0px 0px 10px rgba(19, 43, 58, 0.05),
      0px 4px 20px rgba(19, 43, 58, 0.1);
    border-radius: 3px;
    //padding: 24px 24px 0;
    padding: 0;
    line-height: 24px;
  }
}
.activeMenu {
  display: block;
}
.hideMenu {
  @media (min-width: 768px) and (max-width: 1369px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.5;
    background-color: #132b3a;
    z-index: 2;
  }
}
.hideMenu {
  display: none;
}
.user {
  display: flex;
  position: relative;
  margin-bottom: 22px;
  @media (min-width: 1370px) {
    margin: 24px;
  }
}
.user p {
  margin-bottom: 0;
}
.user span {
  letter-spacing: 0.2px;
  font-size: 16px;
  @media (min-width: 1370px) {
    font-size: 14px;
  }
}
.profileBtn img {
  position: absolute;
  bottom: 0;
  right: -5px;
  background: white;
  border-radius: 50%;
  border: 2px solid #d5dbdf;
  box-shadow: 0px 1px 2px rgb(19 43 58 / 6%), 0px 3px 10px rgb(19 43 58 / 9%);
  @media (min-width: 1370px) {
    display: none;
  }
}
.userInfo {
  width: auto;
  overflow: hidden;
}
.userInfo p:first-child {
  font-weight: 600;
  font-size: 18px;
  font-family: 'Poppins';
}
.userInfo p:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}
.userRole {
  text-transform: capitalize;
}
.copyright {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #70838e;
  @media (min-width: 1370px) {
    display: none;
  }
}
.profileBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e6f1f3;
  margin-right: 16px;
  @media (min-width: 1370px) {
    display: none;
  }
}
.profileBtn > h4 {
  font-size: 18px;
  font-weight: bold;
  color: #132b3a;
  margin: 0;
}
