.footerWrapper {
  text-align: center;

  @media (min-width: 1370px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.links {
  display: flex;
  flex-direction: column;
  order: 2;

  @media (min-width: 768px) and (max-width: 1369px) {
    display: flex;
    flex-direction: row;
    order: 1;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    a:last-child {
      margin-left: auto;
    }
  }
  
  @media (min-width: 1370px) {
    flex-direction: row;
    align-items: center;
  }
}

.links a {
  font-weight: bold;
  font-size: 16px;
  color: #132b3a;
  padding-top: 24px;
  transition: color 0.5s ease-in;

  @media (min-width: 768px) {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .links a:not(:last-child) {
    padding-right: 32px;
  }
}

.links a:hover,
.links a:active {
  color: #40a9ff;
}

.copyright {
  padding-top: 25px;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #70838e;
  order: 1;

  @media (min-width: 768px) and (max-width: 1369px) {
    width: 100%;
    order: 2;
    text-align: left;
  }

  @media (min-width: 1370px) {
    width: auto;
    padding-top: 0;
    margin: 0;
  }
}
