.commentItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}
.commentRight {
  align-items: flex-end;
}
.commentLeft {
  align-items: flex-start;
}
.commentDate {
  width: 100%;
  margin: 6px 0;
  text-align: center;
  font-size: 14px;
  color: #94a5ae;
}
.blockComment {
  width: 250px;
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
  padding: 15px 11px;
  font-size: 16px;
  color: #132b3a;
  border-radius: 4px;
  @media (min-width: 768px) {
    width: 300px;
  }
}
.commentRightBg {
  background: #d7ecf0;
}
.commentLeftBg {
  background: #e6f1f3;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.info .name {
  font-weight: 600;
  font-size: 18px;
  color: #132b3a;
  margin: 0;
}
.info .position {
  margin-left: 5px;
  font-size: 16px;
  color: #70838e;
  white-space: pre-line;
}
.comments {
  color: #3f505a;
  margin-bottom: 16px;
  white-space: pre-line;
}
.commentTime {
  font-size: 12px;
  color: #70838e;
  text-align: right;
  margin-top: 7px;
}

.linkInComment {
  color: #03a3bf;
  &:hover {
    color: #0091b5;
  }
}