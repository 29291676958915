.navList {
  padding-left: 0;
  border-top: 1px solid #ebf0f3;
  border-bottom: 1px solid #ebf0f3;
  margin-bottom: 8px;

  @media (min-width: 1370px) {
    border-bottom: none;
  }
}
.navList li {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 24px;
  margin: 10px 0;
  color: #132b3a;
  font-size: 16px;
  list-style-type: none;
  letter-spacing: 0.2px;
  transition: color 0.5s ease, background-color 0.5s ease;
}
.navList li:first-child {
  margin-top: 10px;
}
.navList li:hover,
.navList li:active,
.navList li:focus {
  font-weight: 600;
  color: #0091b5;
  background-color: #e6f1f3;
  cursor: pointer;
}
.navList li.divider {
  width: 100%;
  height: 1px;
  background: #ebf0f3;
  padding: 0;
  margin: 0;
}
.navList li:nth-child(5) {
  color: #0091b5;
}
.navList li:nth-child(7) {
  border-top: 1px solid #b3bfc6;
  border-bottom: 1px solid #b3bfc6;
  padding: 20px 0;
}
.navList li svg {
  margin-right: 18px;
}
.navList li svg:hover,
.navList li svg:focus,
.navList li svg:active {
  cursor: pointer;
  color: #0091b5;
}
.navList li:nth-child(2) {
  @media (min-width: 1370px) {
    margin-bottom: 10px;
  }
}
.navList li:last-child {
  @media (min-width: 1370px) {
    margin-top: 10px;
  }
}
.onboardingBtn {
  @media (min-width: 1370px) {
    display: none;
  }
}
.superuserList {
  border-bottom: 1px solid #ebf0f3;
}
