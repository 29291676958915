.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin-top: 15px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  font-family: 'Lato';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #3f505a;

  & * {
    line-height: 27px;
    font-size: 18px;
  }

  @media (min-width: 768px) {
    margin-top: 17px;
  }
}

.footer {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.footerButton {
  width: 100%;

  @media (min-width: 768px) {
    width: 160px;
  }
}

.checkInButton {
  background-color: #03a3bf;
}
