.modalContainer {
  max-width: calc(100vw - 32px);
  margin: 0 16px auto;
  padding: 0;
  @media (min-width: 1370px) {
    top: -5% !important;
    width: 790px !important;
  }
}
// .modalContainer.defaultTop {
//   top: 0 !important;
// }
.modalContainer.modalCarousel {
  top: 50% !important;
  transform: translate(0, -50%);
  max-height: 100vh;
  @media (min-width: 1370px) {
    top: 0 !important;
    transform: translate(0, 0);
  }
}
.smallWidth {
  @media (min-width: 1370px) {
    width: 550px !important;
  }
}
.normalWidth {
  @media (min-width: 1370px) {
    width: 790px !important;
  }
}
.modalCarousel {
  position: fixed;
  right: 0;
  left: 0;
  margin: 0;
  width: 100% !important;
  max-width: 100% !important;
  // height: 100%;
  @media (min-width: 1370px) {
    position: static;
    width: 80% !important;
    width: 1238px !important;
  }
}
.modalCarousel > div:nth-child(2) > button span svg {
  width: 25px;
  height: 25px;
  color: white;
}
.modalCarousel > div:nth-child(2) > button span {
  width: auto;
  height: auto;
  line-height: 25px;
}
.modalCarousel > div:nth-child(2) > button {
  margin: 19px 19px 0 0;
}
.modalCarousel > div:nth-child(2) > div:first-of-type {
  padding: 0;
}
.modalCarousel > div:nth-child(2) > div:last-child {
  display: none;
  @media (min-width: 1370px) {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.hideBorderFooter > div:nth-child(2) > div:last-child {
  border-top: 0;
  padding: 24px;
}
.modalWithHeader > div:nth-child(2) > div:first-child > div,
.modalWithHeaderAndClose > div:nth-child(2) > div:first-of-type > div {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
}
.modalWithHeaderAndClose > div:nth-child(2) > div:first-of-type > div {
  margin-right: 20px;
}
.fullModal {
  top: 0 !important;
  position: absolute;
  left: 0;
  margin: 0;
  overflow: hidden;
  width: 100% !important;
  max-width: 100%;
  height: calc(100vh - 64px);
  @media (min-width: 1370px) {
    top: -5% !important;
    position: relative;
    width: 800px !important;
    height: auto;
  }
}
.fullModal > div:nth-child(2) {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  box-shadow: none;
  overflow: auto;
  @media (min-width: 1370px) {
    position: static;
    height: auto;
    width: auto;
  }
}
.fullModal > div:nth-child(2) > div:first-child {
  padding: 12px 16px;
}
.fullModal > div:nth-child(2) > div:nth-child(2) {
  // position: fixed;
  bottom: 0;
  width: 100%;
  // box-shadow: 0px -4px 13px rgba(0, 0, 0, 0.15);
  padding: 12px 16px;
  background: white;
  margin-bottom: 30px;
  @media (min-width: 1370px) {
    position: static;
    width: auto;
    padding: 24px;
    box-shadow: none;
    background: transparent;
    margin-bottom: 0;
  }
}
.wrapBlockOnMobile {
  // z-index: 0;
  z-index: 2;
  top: 64px;
  @media (min-width: 1370px) {
    z-index: 300;
    top: 0;
  }
}
/******* CAROUSEL FOR FILES************/
.carouselFiles {
  position: absolute;
  left: 0;
  top: 64px !important;
  width: 100% !important;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  height: calc(100vh - 64px);
  @media (min-width: 1370px) {
    position: static;
    top: 0 !important;
    width: 90% !important;
    height: 90%;
    overflow: unset;
  }
}
.carouselFiles > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 64px);
  @media (min-width: 1370px) {
    display: block;
    height: auto;
  }
}
.carouselFiles > div:nth-child(2) > button {
  top: 15px;
  right: 15px;
  background: #132b3a;
  opacity: 0.7;
  border-radius: 50%;
  color: white;
  @media (min-width: 1370px) {
    top: 0;
    right: -60px;
  }
}
.carouselFiles > div:nth-child(2) > button > span {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.carouselFiles > div:nth-child(2) > button > span svg {
  width: 15px;
  height: 15px;
}
.carouselFiles > div:nth-child(2) > div:nth-child(2) > div {
  margin: 0;
}
.carouselFiles > div:nth-child(2) > div:nth-child(2) {
  order: 3;
  padding: 15px 10px;
  border: 0;
}
.carouselFiles > div:nth-child(2) > div:nth-child(3) {
  padding: 0;
}
.addTop {
  top: 0 !important;
  @media (min-width: 1370px) {
    top: 64px !important;
  }
}
