html {
  overflow-y: scroll;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main__wrapper {
  display: flex;
  max-width: 1920px;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 auto;
}
.ant-layout,
.ant-layout-footer {
  background-color: #ffffff;
}
.ant-layout-header {
  padding: 0;
}
.ant-layout-footer {
  padding: 32px 16px 24px;
  border-top: 1px solid #d5dbdf;
}
@media (min-width: 768px) and (max-width: 1369px) {
  .ant-layout-header {
    padding: 0 27px;
  }
}
@media (min-width: 1370px) {
  .ant-layout-header {
    padding: 0 24px;
  }

  .ant-layout-footer {
    padding: 34px 24px;
    height: 92px;
  }
}
.ant-btn {
  box-shadow: none;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 12;
}
.ScrollbarsCustom-Thumb {
  background: #0091b5 !important;
  height: 3px !important;
}
.ScrollbarsCustom-Track {
  height: 3px !important;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: block;
}
.ant-steps-item-icon {
  margin: 0 auto;
}
.ant-table-thead > tr > th {
  font-weight: bold;
  background: #c3e0e6;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #38a306;
  border: none;
}
.ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-container > .ant-steps-item-icon > div:first-child {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-steps-navigation .ant-steps-item::before {
  background-color: #38a306;
}
.ant-steps-item-title {
  font-weight: 600;
}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  visibility: hidden;
}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  width: 100% !important;
  left: 0 !important;
}
.ant-dropdown-menu {
  box-shadow: 0px 4px 22px rgb(19 43 58 / 15%);
}
.ant-upload,
.ant-upload-select,
.ant-upload-select-text {
  width: 100%;
  height: 100%;
}
.ant-upload-select {
  display: none;
}
.ant-upload-span .ant-upload-list-item-thumbnail {
  width: 156px;
  height: 156px;
}
.ant-upload-span .ant-upload-list-item-thumbnail img {
  width: 100%;
  height: 100%;
}
.ant-upload-list-picture-container .ant-upload-list-item {
  margin: 0;
  padding: 0;
  height: 156px;
}
.ant-upload-list .ant-upload-list-picture-container {
  border: 1px solid #d5dbdf;
  box-shadow: 0px 0px 10px rgba(19, 43, 58, 0.05),
    0px 4px 20px rgba(19, 43, 58, 0.1);
  margin-bottom: 16px;
}
.ant-btn-link {
  color: #0091b5;
}
.ant-steps-item-container .ant-steps-item-icon {
  border-color: #38a306;
}
.ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
  color: #38a306;
  font-weight: 600;
  letter-spacing: normal;
}
.ant-steps-item.ant-steps-item-wait
  .ant-steps-item-container
  .ant-steps-item-icon {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item.ant-steps-item-wait
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-upload.ant-upload-drag {
  background: #ebf0f3;
  border: 1px dashed #b3bfc6;
}
// .ant-upload-span .ant-upload-list-item-thumbnail {
//   width: 240px;
//   height: 240px;
// }
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0;
}
.ant-upload-list-picture-card-container {
  margin: 0 32px 32px 0;
}
.ant-upload-list-picture-card-container:hover,
.ant-upload-list-picture-card-container:active,
.ant-upload-list-picture-card-container:focus {
  cursor: pointer;
}
.anticon.anticon-delete svg,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  width: 24px;
  height: 24px;
}
.ant-upload-list-item-card-actions-btn {
  margin-left: 25px;
}
.ant-dropdown-trigger:hover,
.ant-dropdown-trigger:active,
.ant-dropdown-trigger:focus {
  cursor: pointer;
}
.ant-tabs-nav-list .ant-tabs-tab {
  padding: 21px 10px 22px;
  margin: 0;
}
.ant-tabs-nav-list .ant-tabs-tab:first-child,
.ant-tabs-nav-list .ant-tabs-tab:last-child {
  padding: 0 10px 0 16px;

  @media (min-width: 1370px) {
    padding: 20px 0;
  }
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  @media (min-width: 1370px) {
    padding: 20px 0;
  }
}
.ant-tabs-nav-list .ant-tabs-tab div {
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #132b3a;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0091b5;
  text-shadow: 0 0 0.25px #0091b5;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 3px;
  background: #03a3bf;
}
.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
.antd-img-crop-modal > div > div:nth-child(2) > div:last-child button {
  display: inline-block;
}
/************************* FOR COLLAPSE ******************************/
.ant-collapse-header {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  color: #132b3a;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  color: #50626c;
}
/************************* FOR FORM ******************************/
.ant-form-vertical .ant-form-item-label > label {
  letter-spacing: 0.2px;
  color: #50626c;
}
.ant-form-vertical .ant-form-item {
  margin-bottom: 12px;
}
.ant-form-item-label > label .ant-form-item-optional {
  font-style: italic;
  font-size: 14px;
}
/************************* FOR CARD ******************************/
.ant-card-meta {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-card-meta-avatar {
  height: 20px;
  padding-right: 12px;
}
.anticon svg {
  width: 20px;
  height: 20px;
}
.ant-card-body {
  padding: 13px 0;
}
.ant-card {
  border: 1px solid #d5dbdf;
  margin-bottom: 17px;
}
.ant-card.ant-card-bordered {
  border: 1px solid #03a3bf;
}
.ant-card .ant-card-actions p {
  color: #b3bfc6;
  margin-bottom: 0;
}
.ant-card .ant-card-body {
  background: #ebf0f3;
  color: #70838e;
}
.ant-card .ant-card-body .ant-card-meta-title {
  font-weight: 600;
  font-size: 18px;
  color: #70838e;
}
.ant-card.ant-card-bordered .ant-card-body {
  background: #c3e0e6;
}
.ant-card.ant-card-bordered .ant-card-body .ant-card-meta-title {
  color: #132b3a;
  font-weight: 700;
}
.ant-card.ant-card-bordered .ant-card-body svg {
  color: #f7941d;
}
.ant-card.ant-card-bordered .ant-card-actions p {
  color: #132b3a;
}
.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header,
.ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding-left: 0;
  padding-bottom: 20px;
  color: #132b3a;
}
.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
}
/************************* FOR TABS LEFT ******************************/
.ant-tabs-left .ant-tabs-tab .ant-tabs-tab-btn > span {
  font-weight: bold;
  font-size: 18px;
}
.ant-tabs-left .ant-tabs-tab .anticon {
  margin-right: 18px;
}
.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0;
}
.ant-tabs-left .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0091b5;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  background: white;
  border: none;
}

/*******************FOR ALL******************/
.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #4ebccf;
}
.ant-btn-primary {
  background: #03a3bf;
  border-color: #03a3bf;
}

.ant-btn.ant-btn-primary:active,
.ant-btn.ant-btn-primary:hover,
.ant-btn.ant-btn-primary:focus {
  color: #fff;
  border-color: #0091b5;
  background: #0091b5;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
}

.ant-btn.ant-btn-link {
  border: none;
}
.ant-btn.ant-btn-link:active,
.ant-btn.ant-btn-link:hover,
.ant-btn.ant-btn-link:focus {
  border: none;
}
.ant-input:active,
.ant-input:hover,
.ant-input:focus {
  border-color: #4ebccf;
}
// .ant-btn:active,
// .ant-btn:hover,
// .ant-btn:focus {
//   color: #03a3bf;
//   border-color: #03a3bf;
// }
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-form-item .ant-form-item-label {
  padding: 0 0 5px;
}
.ant-btn-dangerous {
  color: #fc594c;
  border-color: #fc594c;
  transition: color 500ms ease, border-color 500ms ease;
}
.ant-btn.ant-btn-dangerous:active,
.ant-btn.ant-btn-dangerous:hover,
.ant-btn.ant-btn-dangerous:focus,
.ant-btn.ant-btn-dangerous:active svg,
.ant-btn.ant-btn-dangerous:hover svg,
.ant-btn.ant-btn-dangerous:focus svg {
  color: #ff9c94;
  border-color: #ff9c94;
}
.ant-select-arrow {
  display: flex;
  align-items: center;
}
/********************** RADIO BUTTON *************************/
.ant-radio-wrapper-checked span:last-child {
  text-shadow: 0 0 0.4px #132b3a, 0 0 0.4px #132b3a, 0 0 0.4px #132b3a;
  font-size: 18px;
  color: #132b3a;

  @media (min-width: 1370px) {
    font-size: 16px;
  }
}
.radioBlock label.ant-radio-wrapper.ant-radio-wrapper-checked {
  background: #e6f1f3;
  border: 1px solid #03a3bf;
  box-shadow: 0px 0px 10px rgba(19, 43, 58, 0.05),
    0px 4px 20px rgba(19, 43, 58, 0.1);
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #0091b5;
}
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #0091b5;
}
.ant-radio-wrapper .ant-radio-checked::after {
  // border: 1px solid #0091B5;
  border: none;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0091b5;
}
/************MODAL WINDOW***************/
.ant-modal-mask {
  background: rgba(19, 43, 58, 0.7);
}
/**********SKELETON ***********/
.ant-skeleton-paragraph {
  margin: 0;
}
/************CHECKBOX COLOR***************/
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0091b5;
  border-color: #0091b5;
}
/************PAGINATION COLOR***************/
.ant-pagination-item-active a,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #03a3bf;
}
.ant-pagination-options svg {
  color: inherit;
}
/************CAROUSEL***************/
.ant-carousel .slick-dots li.slick-active button {
  background: #4ebccf;
  border: 1px solid #4ebccf;
}

/************Payment method form item***************/
.payment_formItem.ant-form-item {
  margin-bottom: 2rem;
}

/********* SWIPER ON MODILE *************/
.swiper .adm-swipe-action-actions.adm-swipe-action-actions-right {
  display: flex;
  @media (min-width: 1370px) {
    display: none;
  }
}

/********* POPOVER *************/
.ant-popover-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}