.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin-top: 15px;
  margin-bottom: 0;

  font-family: 'Lato';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #3f505a;

  @media (min-width: 768px) {
    margin-top: 17px;
  }
}

.subtext {
  margin-bottom: 12px;
  color: #89939a;
  font-size: 14px;
  line-height: 22px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerButton {
  background-color: #03a3bf;
  width: 100%;

  @media (min-width: 768px) {
    width: 160px;
  }
}
